<script>
export default {
  components: {
    FilterDashboard: () => import('./Filter.vue'),
    ChartFour: () => import('./ChartFour.vue'),
    ChartFive: () => import('./ChartFive.vue'),
    ChartSix: () => import('./ChartSix.vue'),
  },
}
</script>

<template>
  <div class="pb-10 print">
    <filter-dashboard :class="$vuetify.breakpoint.xs? 'no-print' : $vuetify.breakpoint.sm? 'no-print' : 'filter no-print'"/>
    <h3 class="mt-8 mb-3">Oferta de Pré Pastejo</h3>
    <chart-four />
    <h3 class="mt-8 mb-3">Lotação e demanda de pasto do módulo</h3>
    <chart-five />
    <h3 class="mt-8 mb-3">Oferta e demanda</h3>
    <chart-six />
  </div>
</template>

<style scoped>
.filter {
  z-index: 3;
  position: fixed;
  top: 70px;
  width: calc(100vw - 40px);
  max-width: 1276px;
}
div >>> .apexcharts-toolbar {
  z-index: 2 !important;
}
</style>
